/*
 * This file (which will be your service worker)
 * is picked up by the build system ONLY if
 * quasar.conf > pwa > workboxPluginMode is set to "InjectManifest"
 */
/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import { precacheAndRoute } from 'workbox-precaching'

precacheAndRoute(self.__WB_MANIFEST)

self.addEventListener('push', function (event) {
  if (event.data) {
    console.log('This push event has data: ', event.data.text())
  } else {
    console.log('This push event has no data.')
  }
  console.log('Received a push message', event)
  console.log(JSON.stringify(event))
  var data = event.data.json()
  var title = data.title
  var body = data.body
  var icon = data.icon
  var badge = data.badge
  var url = data.url

  event.waitUntil(
    self.registration.showNotification(title, {
      body: body,
      icon: icon,
      badge: badge,
      data: { url: url }
    })
  )
})

self.addEventListener('notificationclick', function (event) {
  console.log('On notification click: ', event.notification.tag)
  // Android doesn’t close the notification when you click on it
  // See: http://crbug.com/463146
  event.notification.close()

  // This looks to see if the current is already open and
  // focuses if it is
  event.waitUntil(self.clients.matchAll({
    type: 'window'
  }).then(function (clientList) {
    for (var i = 0; i < clientList.length; i++) {
      var client = clientList[i]
      if (client.url === '/' && 'focus' in client) {
        return client.focus()
      }
    }
    if (self.clients.openWindow) {
      return self.clients.openWindow('/')
    }
  }))
})
